import React, { useContext, createContext, useState } from 'react';

import { AuthDialog } from './AuthDialog/AuthDialog';
import { trackEvent } from '../analytics/analytics';
import { LOGIN_MODAL_VIEWED } from '../analytics/constants';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  route: string;
};

export type Context = {
  isDialogOpen: boolean;
  openAuthDialog: (title: string, successRedirectUrl?: string) => void;
};

export const AuthenticationDialogContext = createContext<Context>({
  isDialogOpen: false,
  openAuthDialog: () => {},
});

export const useAuthenticationDialogContext = (): Context =>
  useContext(AuthenticationDialogContext);

export const AuthenticationProvider = ({ children }: Props): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [onLoginSuccessRedirectUrl, setOnLoginSuccessRedirectUrl] = useState('');

  const openAuthDialog = (newTitle: string, successRedirectUrl?: string): void => {
    trackEvent(LOGIN_MODAL_VIEWED);
    setIsDialogOpen(true);
    setTitle(newTitle);
    setOnLoginSuccessRedirectUrl(successRedirectUrl ?? '');
  };

  const closeAuthDialog = (): void => {
    setIsDialogOpen(false);
  };

  return (
    <AuthenticationDialogContext.Provider
      value={{
        isDialogOpen: isDialogOpen,
        openAuthDialog,
      }}
    >
      <>
        {children}
        <AuthDialog
          handleDialogClose={closeAuthDialog}
          isOpen={isDialogOpen}
          onLoginSuccessRedirectUrl={onLoginSuccessRedirectUrl}
          title={title}
        />
      </>
    </AuthenticationDialogContext.Provider>
  );
};
