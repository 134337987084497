import {
  VisuallyHidden,
  IconFacebook,
  IconInstagram,
  TappableLinkContainer,
} from '@madpaws/design-system';

import { LEGAL_LINKS } from '~/common/constants/app';

import styles from './PlatformFooterCopyrightAndSublinks.module.css';

import type { ReactElement } from 'react';

export const PlatformFooterCopyrightAndSublinks = (): ReactElement => (
  <div className={styles.root}>
    <div className={styles.socialLinks}>
      <TappableLinkContainer>
        <a href="https://www.facebook.com/madpawsau" rel="noopener noreferrer" target="_blank">
          <VisuallyHidden isInline>Mad Paws Facebook page</VisuallyHidden>
          <IconFacebook size="large" />
        </a>
      </TappableLinkContainer>
      <TappableLinkContainer>
        <a href="https://www.instagram.com/madpawsau/" rel="noopener noreferrer" target="_blank">
          <VisuallyHidden isInline>Mad Paws Instagram page</VisuallyHidden>
          <IconInstagram size="large" />
        </a>
      </TappableLinkContainer>
    </div>
    <div className={styles.copyright}>
      <p>
        <small>Copyright ©{new Date().getFullYear()} Mad Paws</small>
      </p>
      {LEGAL_LINKS.map((link) => (
        <TappableLinkContainer key={link.label}>
          <a href={link.href}>{link.label}</a>
        </TappableLinkContainer>
      ))}
    </div>
  </div>
);
