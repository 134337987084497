import type { ContextualLinks } from './types';

export const STATIC_CONTEXTUAL_LINKS: ContextualLinks[] = [
  {
    heading: 'Our Services',
    links: [
      {
        label: `At Sitter's Home`,
        href: 'https://www.madpaws.com.au/pet-sitters/pet-hosting',
      },
      {
        label: 'Dog Walking',
        href: 'https://www.madpaws.com.au/pet-sitters/dog-walking-services',
      },
      {
        label: `At Pet's Home`,
        href: 'https://www.madpaws.com.au/pet-sitters/house-pet-sitting',
      },
      {
        label: 'Pet Day Care',
        href: 'https://www.madpaws.com.au/pet-sitters/doggy-daycare',
      },
      {
        label: 'Dog Sitting',
        href: 'https://www.madpaws.com.au/pet-sitters/dog-sitting-boarding',
      },
      {
        label: 'House Visiting',
        href: 'https://www.madpaws.com.au/pet-sitters/pet-care-services',
      },
      {
        label: 'Cat Sitting',
        href: 'https://www.madpaws.com.au/pet-sitters/cat-sitting-boarding',
      },
      {
        label: 'Bird Sitting',
        href: 'https://www.madpaws.com.au/pet-sitters/bird-sitting-boarding',
      },
      {
        label: 'Puppy Sitting',
        href: 'https://www.madpaws.com.au/pet-sitters/puppy-sitting-boarding',
      },
      {
        label: 'Dog Grooming',
        href: 'https://www.madpaws.com.au/pet-sitters/dog-grooming-services',
      },
      {
        label: 'Rabbit Sitting',
        href: 'https://www.madpaws.com.au/pet-sitters/rabbit-sitting-boarding',
      },
      {
        label: 'Dog Training',
        href: 'https://www.madpaws.com.au/pet-sitters/dog-puppy-trainer',
      },
      {
        label: 'Dog Kennels',
        href: 'https://www.madpaws.com.au/pet-sitters/dog-kennels',
      },
      {
        label: 'Pet Services',
        href: 'https://www.madpaws.com.au/pet-sitters/pet-services',
      },
      {
        label: 'House Sitting',
        href: 'https://www.madpaws.com.au/pet-sitters/house-sitting',
      },
      {
        label: 'Dog Boarding',
        href: 'https://www.madpaws.com.au/pet-sitters/dog-boarding',
      },
      {
        label: 'Guinea Pig Sitting',
        href: 'https://www.madpaws.com.au/pet-sitters/guinea-pig-sitting-boarding',
      },
    ],
  },
];

export const TOP_PET_SITTING_CITIES = [
  {
    slug: 'sydney-nsw',
    name: 'Sydney',
  },
  {
    slug: 'ballarat-vic',
    name: 'Ballarat',
  },
  {
    slug: 'melbourne-vic',
    name: 'Melbourne',
  },
  {
    slug: 'townsville-qld',
    name: 'Townsville',
  },
  {
    slug: 'brisbane-qld',
    name: 'Brisbane',
  },
  {
    slug: 'bendigo-vic',
    name: 'Bendigo',
  },
  {
    slug: 'perth-wa',
    name: 'Perth',
  },
  {
    slug: 'hobart-tas',
    name: 'Hobart',
  },
  {
    slug: 'adelaide-sa',
    name: 'Adelaide',
  },
  {
    slug: 'newcastle-nsw',
    name: 'Newcastle',
  },
  {
    slug: 'canberra-act',
    name: 'Canberra',
  },
  {
    slug: 'toowoomba-qld',
    name: 'Toowoomba',
  },
  {
    slug: 'gold-coast-qld',
    name: 'Gold Coast',
  },
  {
    slug: 'wollongong-nsw',
    name: 'Wollongong',
  },
  {
    slug: 'central-coast-nsw',
    name: 'Central Coast',
  },
  {
    slug: 'geelong-vic',
    name: 'Geelong',
  },
];
