import { RestApi } from '../restApi';

import type { ValidateEmailResponse } from './types';
import type { AxiosError, AxiosResponse } from 'axios';

export class AuthApi extends RestApi {
  static async validateEmail(email: string): Promise<ValidateEmailResponse | null> {
    try {
      const response: AxiosResponse<ValidateEmailResponse> = await this.woofApi.post(
        '/v3/users/validate_email',
        { email }
      );

      return { ...response.data };
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }
}
