import { createContext, useContext } from 'react';

import type { ContextualLinks } from './types';
import type { Dispatch, SetStateAction } from 'react';

export type ContextualLinksState = Array<ContextualLinks>;

export const ContextualLinksContext = createContext<ContextualLinksState>([]);
export const ContextualLinksDispatchContext = createContext<
  Dispatch<SetStateAction<ContextualLinksState>>
>(() => {});

export const useContextualLinks = (): ContextualLinksState => useContext(ContextualLinksContext);
export const useContextualLinksDispatch = (): Dispatch<SetStateAction<ContextualLinksState>> =>
  useContext(ContextualLinksDispatchContext);
