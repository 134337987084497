import { createContext } from 'react';

import type { ParsedSplitIOTreatmentsWithConfig } from './SplitIOProvider';
import type SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';

export type Context = {
  getTreatments: () => Promise<SplitIO.Treatments>;
  isSplitIoLoading: boolean;
  treatments: SplitIO.Treatments;
  treatmentsWithConfig: ParsedSplitIOTreatmentsWithConfig;
};

export const SplitIOContext = createContext<Context>({
  getTreatments: () => Promise.resolve({}),
  isSplitIoLoading: false,
  treatments: {},
  treatmentsWithConfig: {},
});
