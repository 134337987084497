import { DEFAULT_SERVICE_JOB, DEFAULT_SERVICE_SLUG } from '~/common/constants/search';

import { TOP_PET_SITTING_CITIES } from './constants';

import type { ContextualLinks } from '~/components/PlatformFooter/PlatformFooterContextualLinks/types';

export const getTopPetSittingCitiesLinks = (): ContextualLinks => {
  const links = TOP_PET_SITTING_CITIES.map(({ slug, name }) => ({
    label: `${DEFAULT_SERVICE_JOB} ${name}`,
    href: `/${DEFAULT_SERVICE_SLUG}/${slug}`,
  }));

  links.push({
    label: 'And many more cities!',
    href: 'https://www.madpaws.com.au/about/top-pet-sitting-and-dog-boarding-cities',
  });

  return {
    heading: 'Top Pet Sitting Cities',
    links,
  };
};
