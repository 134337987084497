import { useContext } from 'react';

import { SplitIOContext } from './context';

import type { Context } from './context';

type Props = Context & {
  getDynamicConfigEnabled: (
    splitId: string,
    featureOnValue: string,
    dynamicConfigKey: string
  ) => boolean;
  isSplitIoLoading: boolean;
};

export const useSplitIO = (): Props => {
  const { isSplitIoLoading, getTreatments, treatments, treatmentsWithConfig } = useContext(
    SplitIOContext
  );

  const getDynamicConfigEnabled = (
    splitId: string,
    featureOnValue: string,
    dynamicConfigKey: string
  ): boolean =>
    !isSplitIoLoading &&
    treatmentsWithConfig[splitId]?.treatment === featureOnValue &&
    treatmentsWithConfig[splitId]?.config[dynamicConfigKey];

  return {
    getTreatments,
    treatments,
    isSplitIoLoading,
    treatmentsWithConfig,
    getDynamicConfigEnabled,
  };
};
