import { ApolloClient, InMemoryCache, createHttpLink, concat } from '@apollo/client';

import { authLink } from './middlewares';

// NOTE: see next.config.js custom webpack config for an explanation on why
// there are two client files: client.ts and client.ssr.ts.
export const client = new ApolloClient({
  link: concat(authLink, createHttpLink({ fetch, uri: '/api/graphql' })),
  cache: new InMemoryCache({
    typePolicies: {
      Badge: {
        keyFields: ['id', 'name'],
      },
    },
  }),
  ssrMode: false,
});
