type JSONValue = string | number | boolean | JSONObject | JSONArray;
interface JSONObject {
  [x: string]: JSONValue;
}
type JSONArray = Array<JSONValue>;

export const getLocalStorage = (key: string, initialValue: JSONValue): JSONValue =>
  window?.localStorage.getItem(key) ?? initialValue;

export const setLocalStorage = (key: string, value: JSONValue): void => {
  window?.localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key: string): void => {
  window?.localStorage.removeItem(key);
};
