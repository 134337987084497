import { TappableLinkContainer } from '@madpaws/design-system';
import getConfig from 'next/config';

import styles from './PlatformFooterMainNavigationSection.module.css';
import { PlatformFooterNavigationLayoutHeading } from '../../PlatformFooterNavigationLayout/PlatformFooterNavigationLayoutHeading';

import type { Link as LinkType } from '../types';
import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

type Props = {
  heading: string;
  links: LinkType[];
};

export const PlatformFooterMainNavigationSection = ({ heading, links }: Props): ReactElement => (
  <div className={styles.root}>
    <PlatformFooterNavigationLayoutHeading>{heading}</PlatformFooterNavigationLayoutHeading>
    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
    <ul role="list">
      {links.map(({ label, href, image, height, width, isExternal = false }) => (
        <li key={href}>
          {image ? (
            <TappableLinkContainer>
              <a
                className={styles.imageLink}
                href={href}
                {...(isExternal ? { rel: 'noopener noreferrer', target: '_blank' } : {})}
              >
                <img
                  alt={label}
                  height={height}
                  loading="lazy"
                  src={`${publicRuntimeConfig.staticPath}/images/${image}`}
                  width={width}
                />
              </a>
            </TappableLinkContainer>
          ) : (
            <TappableLinkContainer>
              <a
                href={href}
                {...(isExternal ? { rel: 'noopener noreferrer', target: '_blank' } : {})}
              >
                {label}
              </a>
            </TappableLinkContainer>
          )}
        </li>
      ))}
    </ul>
  </div>
);
