export const SEARCH_FILTERS_FORM_ID = 'searchFiltersForm';
export const SERVICE_AND_BOOKING_TYPE_FORM_ID = 'serviceAndBookingTypeForm';

export const SERVICE_NAMES = {
  petSitting: 'petSitting',
  petHosting: 'petHosting',
  petDayCare: 'petDayCare',
  houseVisiting: 'houseVisiting',
  dogWalking: 'dogWalking',
  training: 'training',
  grooming: 'grooming',
};

export const OVERNIGHT_SERVICE_NAMES = [SERVICE_NAMES.petSitting, SERVICE_NAMES.petHosting];
export const SITTER_HOME_BASED_SERVICE_NAMES = [SERVICE_NAMES.petHosting, SERVICE_NAMES.petDayCare];

export const RWB_SERVICE_NAMES = [
  SERVICE_NAMES.petDayCare,
  SERVICE_NAMES.houseVisiting,
  SERVICE_NAMES.dogWalking,
];

export const SEARCH_FILTERS_FORM_KEYS = {
  address: 'location.address',
  bookingType: 'bookingType',
  endDate: 'chronology.endDate',
  petTypes: 'petTypes',
  rwbStartDate: 'chronology.rwbStartDate',
  scheduledDates: 'chronology.scheduledDates',
  serviceType: 'service.type',
  startDate: 'chronology.startDate',
  query: 'location.query',
  weekDays: 'chronology.weekDays',
};

export const WEEK_DAYS = [
  {
    label: 'Monday',
    name: 'monday',
    value: 'monday',
    displayValue: 'Mon',
  },
  {
    label: 'Tuesday',
    name: 'tuesday',
    value: 'tuesday',
    displayValue: 'Tue',
  },
  {
    label: 'Wednesday',
    name: 'wednesday',
    value: 'wednesday',
    displayValue: 'Wed',
  },
  {
    label: 'Thursday',
    name: 'thursday',
    value: 'thursday',
    displayValue: 'Thu',
  },
  {
    label: 'Friday',
    name: 'friday',
    value: 'friday',
    displayValue: 'Fri',
  },
  {
    label: 'Saturday',
    name: 'saturday',
    value: 'saturday',
    displayValue: 'Sat',
  },
  {
    label: 'Sunday',
    name: 'sunday',
    value: 'sunday',
    displayValue: 'Sun',
  },
];

export const MOBILE_SCROLL_POSITION = 300;
export const DESKTOP_SCROLL_POSITION = 100;
