import { LayoutGridContainer, LayoutGridItem } from '@madpaws/design-system';
import React from 'react';

import { PlatformFooterContextualLinks } from './PlatformFooterContextualLinks/PlatformFooterContextualLinks';
import { PlatformFooterCopyrightAndSublinks } from './PlatformFooterCopyrightAndSublinks/PlatformFooterCopyrightAndSublinks';
import { PlatformFooterMainNavigation } from './PlatformFooterMainNavigation/PlatformFooterMainNavigation';

import type { ReactElement } from 'react';

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 */
const PLATFORM_FOOTER_HTML_ID = 'Platform_footer';

export const PlatformFooter = (): ReactElement => (
  <LayoutGridContainer element="footer" id={PLATFORM_FOOTER_HTML_ID} isRootLayout>
    <LayoutGridItem placement={{ small: [1, 13], medium: [2, 12], large: [2, 12] }}>
      <nav aria-label="Secondary">
        <PlatformFooterContextualLinks />
        <PlatformFooterMainNavigation />
      </nav>
      <PlatformFooterCopyrightAndSublinks />
    </LayoutGridItem>
  </LayoutGridContainer>
);
