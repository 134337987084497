import * as Sentry from '@sentry/nextjs';

import type { Extras } from '@sentry/types';

export const sentryMessage = (message: string, extra?: object): void => {
  Sentry.withScope((scope) => {
    scope.setExtras(extra as Extras);
    Sentry.captureMessage(message);
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const sentryException = (error: any, extraPayload?: any): void => {
  const extras: Extras | [] = {};

  // attach api errors if exist
  extras.errors = error?.extensions?.response?.body?.errors || null;

  // attach extra payload if provided
  extras.payload = extraPayload || null;

  Sentry.withScope((scope) => {
    scope.setExtras(extras as Extras);
    Sentry.captureException(error);
  });
};
