import { client as apolloClient } from '~/api/graphql/client';

import { GET_FEATURE_FLAGS } from './queries';

import type { FeatureFlag } from '~/common/types/settings';

export const getFeatureFlags = async (): Promise<FeatureFlag[]> => {
  const {
    data: { featureFlags },
  } = await apolloClient.query({
    query: GET_FEATURE_FLAGS,
    fetchPolicy: 'no-cache',
  });

  return featureFlags;
};
