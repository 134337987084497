import Cookies from 'js-cookie';

import {
  PET_HOSTING_SERVICE_ID,
  PET_SITTING_SERVICE_ID,
  PET_DAY_CARE_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
} from '~/common/constants/app';
import { EMPTY_SERVICE_TYPE_ID } from '~/common/constants/search';
import {
  USER_DISTINCT_ID_PARAMETER,
  SEGMENT_ANONYMOUS_ID_COOKIE_NAME,
} from '~/common/constants/user';
import {
  convertServiceAndLocationToUrlStructure,
  getServiceTypeIdFromSearchFilters,
} from '~/common/utils/search';

import type {
  AdvancedFilters,
  LocationValues,
  PetTypes,
  SearchFilters,
  SearchResultsArgs,
  UrlValues,
} from '~/common/types/search';

export const DEFAULT_LOCATION_PROPERTIES = {
  lat: -33.8688197,
  lng: 151.2092955,
  postcode: '1021',
  q: 'Sydney NSW, Australia',
  stateName: 'NSW',
  suburb: 'Sydney',
};

export const EMPTY_LOCATION_PROPERTIES = {
  lat: null,
  lng: null,
  postcode: '',
  q: '',
  stateName: '',
  suburb: '',
};

export const BOOKING_TYPE_MARKETPLACE = 'marketplace';
export const BOOKING_TYPE_RWB = 'rwb';
export const BOOKING_TYPES = [BOOKING_TYPE_MARKETPLACE, BOOKING_TYPE_RWB];

export const petTypesReverseMapping: { [key: string]: number } = {
  smallDog: 1,
  mediumDog: 2,
  largeDog: 3,
  giantDog: 4,
  puppy: 5,
  cat: 6,
  bird: 7,
  creature: 8,
};

const getLocationDataFromSearchFilters = ({
  address,
  query,
}: LocationValues): Pick<
  SearchResultsArgs,
  'lat' | 'lng' | 'postcode' | 'q' | 'stateName' | 'suburb'
> => {
  const { lat, lng, postcode, stateName, suburb } = address;

  if (!lat || !lng || !postcode || !query || !stateName || !suburb) {
    return DEFAULT_LOCATION_PROPERTIES;
  }

  return {
    lat: lat as number,
    lng: lng as number,
    postcode: postcode as string,
    q: query as string,
    stateName: stateName as string,
    suburb: suburb as string,
  };
};

export const getBookingTypeFromUrl = (bookingType: string | undefined): string => {
  if (!bookingType || !BOOKING_TYPES.includes(bookingType)) {
    return BOOKING_TYPE_MARKETPLACE;
  }

  return bookingType;
};

export const getPetTypesFromSearchFilters = (petTypes: PetTypes): string =>
  Object.entries(petTypes)
    .filter((petTypeEntry) => {
      const [, value] = petTypeEntry;
      return !!value;
    })
    .map((petTypeEntry) => {
      const [petName, petCount] = petTypeEntry;

      const petTypeIds = [];

      for (let i = 0; i < Number(petCount); i += 1) {
        petTypeIds.push(petTypesReverseMapping[petName]);
      }

      return petTypeIds.join(',');
    })
    .filter((petTypeEntry) => typeof petTypeEntry === 'string' && petTypeEntry.length > 0)
    .join(',');

export const mapSearchFiltersToUrlStructure = ({ location, service }: UrlValues): string => {
  const locationProperties = getLocationDataFromSearchFilters(location);
  const { suburb, stateName } = locationProperties;
  const serviceType = getServiceTypeIdFromSearchFilters(service);

  const urlStructure = convertServiceAndLocationToUrlStructure(serviceType, suburb, stateName);
  return urlStructure;
};

type RelevantSearchFilters = {
  bookingType: string;
  endDate?: string;
  petTypes: string;
  price?: string;
  reviewsMin?: string;
  scheduledDates?: string;
  service: number;
  sitterOptions?: string;
  sort: string;
  startDate?: string;
  startDateOfWeek?: string;
  weekDays?: string;
};

// NOTE: exported for testing purposes only
export const filterRelevantFilters = ({
  bookingType,
  endDate,
  petTypes,
  scheduledDates,
  service,
  sort,
  startDate,
  startDateOfWeek,
  weekDays,
  sitterOptions,
  price,
  reviewsMin,
}: RelevantSearchFilters): RelevantSearchFilters => {
  let extraFilters = {};

  switch (service) {
    case PET_HOSTING_SERVICE_ID:
    case PET_SITTING_SERVICE_ID:
    case EMPTY_SERVICE_TYPE_ID:
    default:
      extraFilters = { startDate, endDate, bookingType: BOOKING_TYPE_MARKETPLACE };
      break;
    case PET_DAY_CARE_SERVICE_ID:
    case HOUSE_VISITING_SERVICE_ID:
    case DOG_WALKING_SERVICE_ID:
      extraFilters =
        bookingType === BOOKING_TYPE_RWB
          ? { startDateOfWeek, weekDays }
          : { scheduledDates, bookingType: BOOKING_TYPE_MARKETPLACE };
      break;
    case DOG_GROOMING_SERVICE_ID:
    case DOG_TRAINING_SERVICE_ID:
      extraFilters = { scheduledDates, bookingType: BOOKING_TYPE_MARKETPLACE };
  }

  return {
    bookingType,
    petTypes,
    service,
    sort,
    sitterOptions,
    price,
    reviewsMin,
    ...extraFilters,
  };
};

// NOTE: exported for testing purposes only
export const getSitterOptionsFromSearchFilters = (advancedFilters?: AdvancedFilters): string => {
  if (!advancedFilters) {
    return '';
  }
  const { aboutSitter = [], sittersHome = [], sittersPetsKids = [] } = advancedFilters || {};

  const combinedSitterOptions: string[] = aboutSitter.concat(sittersHome, sittersPetsKids);

  return combinedSitterOptions.join(',');
};

export const mapSearchFiltersToUrlQueryParameters = (
  {
    bookingType,
    chronology: { endDate, rwbStartDate, scheduledDates, startDate, weekDays },
    location,
    petTypes,
    service,
    sort,
    advancedFilters,
  }: SearchFilters,
  excludeLocation = false
): string => {
  const booking = getBookingTypeFromUrl(bookingType);
  const locationProperties = getLocationDataFromSearchFilters(location);
  const serviceType = getServiceTypeIdFromSearchFilters(service);
  const pets = getPetTypesFromSearchFilters(petTypes);
  const sitterOptions = getSitterOptionsFromSearchFilters(advancedFilters);
  const price = advancedFilters?.price.join(',');

  const filters = {
    bookingType: booking,
    endDate,
    petTypes: pets,
    scheduledDates: scheduledDates?.join(','),
    service: serviceType,
    sort: sort ?? 'performance',
    startDate,
    startDateOfWeek: rwbStartDate,
    reviewsMin: advancedFilters?.reviewsMin,
    weekDays: [
      weekDays.sunday ? 1 : 0,
      weekDays.monday ? 1 : 0,
      weekDays.tuesday ? 1 : 0,
      weekDays.wednesday ? 1 : 0,
      weekDays.thursday ? 1 : 0,
      weekDays.friday ? 1 : 0,
      weekDays.saturday ? 1 : 0,
    ].join(','),
    sitterOptions,
    price,
  };

  let relevantFilters = filterRelevantFilters(filters);

  if (!excludeLocation) {
    relevantFilters = {
      ...relevantFilters,
      ...locationProperties,
    };
  }

  const response = new URLSearchParams();
  Object.entries(relevantFilters).forEach(([key, value]) => {
    if (value !== undefined) {
      if (typeof value === 'string' && value.length > 0) {
        response.append(key, value);
      } else if (Array.isArray(value) || typeof value === 'number') {
        response.append(key, value.toString());
      }
    }
  });

  return response.toString();
};

/**
 * For some reason getServerSideProps works well with cookies retrieved on
 * initial page load, but cannot retrieve them during client side navigation.
 * This is despite the request being the same and the cookies being attached
 * to the request if you check the browser network tab.
 *
 * This issue is not reproduced for local development,
 * but you can see the empty array for context.req.cookies when
 * the code is executed on staging or production and the same for req.headers
 *
 * You can check these issues if they were resolved, but so far
 * not solution suggested:
 * https://github.com/vercel/next.js/issues/14927
 * https://stackoverflow.com/questions/68446283/next-js-redirect-after-login-from-keycloak-missing-cookies-in-headers-in-get/70256772#70256772
 *
 * So, to fix this issue, the value will be passed as get parameter instead
 * of the cookie. This workaround is used only for the client side navigation
 * For the initial page loading we still need to check the cookie.
 * It's used when search is opened directly or from other pages, so the cookie
 * is created and can be used by SSR, but the url parameter is not added
 */
export const addDistinctIdFromCookieAsGetParameterOnBrowserLevel = (url: string): string => {
  if (typeof window === 'undefined') {
    return url;
  }

  /**
   * We switched from our custom distinctID to the segmentAnonymousID
   * due to exceeding monthly tracked keys in split.io,
   * with the search algorithm accounting for 25% of our total keys.
   */
  const segmentAnonymousId = Cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME);
  const userDistinctIdParameters = `${USER_DISTINCT_ID_PARAMETER}=${segmentAnonymousId}`;

  // exclude missed cookie and parameter duplications
  if (!segmentAnonymousId || url.includes(segmentAnonymousId)) {
    return url;
  }

  const separator = url.includes('?') ? '&' : '?';

  return `${url}${separator}${userDistinctIdParameters}`;
};

export const prepareUrl = (newSearchFilters: SearchFilters): string => {
  const params = mapSearchFiltersToUrlQueryParameters(newSearchFilters);
  const urlStructure = mapSearchFiltersToUrlStructure(newSearchFilters);

  const url = addDistinctIdFromCookieAsGetParameterOnBrowserLevel(`${urlStructure}?${params}`);

  return url;
};
