import Cookies from 'js-cookie';
import { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';

import { ACCESS_TOKEN_COOKIE_NAME } from '~/common/constants/auth';

import type { ReactElement } from 'react';

export type Context = {
  isUserLoggedIn: boolean | null;
  setIsUserLoggedIn(isLoggedIn: boolean): void;
};

// export for testing purpose
export const UserLoggedInContext = createContext<Context>({
  isUserLoggedIn: false,
  setIsUserLoggedIn: () => {},
});

export const useUserLoggedInContext = (): Context => useContext(UserLoggedInContext);

type Props = {
  children: ReactElement | ReactElement[];
};

export const UserLoggedInProvider = ({ children }: Props): ReactElement => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    // initialize isUserLoggedIn
    const isAuthCookiesProvided = !!Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    setIsUserLoggedIn(isAuthCookiesProvided);
  }, []);

  return (
    <UserLoggedInContext.Provider value={{ isUserLoggedIn, setIsUserLoggedIn }}>
      {children}
    </UserLoggedInContext.Provider>
  );
};
