import Bowser from 'bowser';
import md5 from 'md5';

import type { IncomingMessage } from 'http';

export const ScreenWidths = {
  SMALL: 767.98, // 0-767.98
  MEDIUM: 1023.98, // 768-1023.98
  LARGE: 1279.98, // 1024-1279.98
};

const UserAgentTypeScreenWidthMap: { [key: string]: number } = {
  bot: ScreenWidths.SMALL,
  mobile: ScreenWidths.SMALL,
  tablet: ScreenWidths.MEDIUM,
  desktop: ScreenWidths.LARGE,
  tv: ScreenWidths.LARGE,
};

export const inferScreenWidthFromUserAgent = (req: IncomingMessage | undefined): number => {
  if (!req) {
    return ScreenWidths.SMALL;
  }

  const userAgent = req.headers['user-agent'];

  if (!userAgent) {
    return ScreenWidths.SMALL;
  }

  const parsedUserAgent = Bowser.parse(userAgent);

  if (!parsedUserAgent) {
    return ScreenWidths.SMALL;
  }

  const platformType =
    parsedUserAgent.platform && parsedUserAgent.platform.type
      ? parsedUserAgent.platform.type
      : 'mobile';

  const screenWidth = UserAgentTypeScreenWidthMap[platformType];

  return screenWidth || ScreenWidths.SMALL;
};

// NOTE: exported for testing purposes only
export const detectCrawler = (userAgent: string): boolean => {
  const crawlerRegex = /bot|crawler|spider|crawling/i;

  return crawlerRegex.test(userAgent);
};

// Helper function to parse user agent header.
// It's necessary to have these details clearer
// for further logging on API side.
// This work is a part of debugging split.io usage,
// see more details - https://madpaws.atlassian.net/browse/SP-724
export const getClientUserAgentDetails = (request: IncomingMessage): string => {
  const userAgent = request.headers['user-agent'];

  if (!userAgent) {
    return '';
  }

  const parsedUserAgent = Bowser.parse(userAgent);

  if (!parsedUserAgent) {
    return '';
  }

  const { browser, os, platform } = parsedUserAgent;
  const isBot = detectCrawler(userAgent) ? 'yes' : 'no';

  return `${browser.name};${os.name};${platform.type};${isBot}`;
};

// Helper function to generate hash of given user-agent.
// It's necessary for further logging on API side.
// This work is a part of debugging split.io usage.
// md5 library should be removed after investigation,
// see more details - https://madpaws.atlassian.net/browse/SP-724
export const getClientUserAgentHash = (request: IncomingMessage): string => {
  const userAgent = request.headers['user-agent'];

  if (!userAgent) {
    return '';
  }

  return md5(userAgent);
};
