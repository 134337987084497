import { FeatureFlagsContext } from './context';

import type { ReactElement, ReactNode } from 'react';
import type { FeatureFlag } from '~/common/types/settings';

type Props = {
  children: ReactNode;
  featureFlags: FeatureFlag[];
};

export const FeatureFlagsProvider = ({ featureFlags, children }: Props): ReactElement => (
  <FeatureFlagsContext.Provider value={{ featureFlags }}>{children}</FeatureFlagsContext.Provider>
);
