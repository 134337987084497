import {
  PET_HOSTING_SERVICE_ID,
  PET_SITTING_SERVICE_ID,
  PET_DAY_CARE_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
  DEFAULT_SERVICE_TYPE_ID,
} from '~/common/constants/app';
import {
  DEFAULT_SERVICE_SLUG,
  searchServiceSlugMapping,
  SERVICE_TYPE_BY_NAME_MAPPING,
} from '~/common/constants/search';

import type { ServiceFilterDetails } from '~/common/types/search';

export const convertServiceAndLocationToUrlStructure = (
  serviceType: number,
  suburb: string | undefined,
  stateName: string | undefined
): string => {
  const suburbSlug = suburb?.toLowerCase().replace(/ /g, '-');
  const stateSlug = stateName?.toLowerCase();

  const serviceSlug = Object.keys(searchServiceSlugMapping).find(
    (key) => searchServiceSlugMapping[key] === serviceType
  );

  const urlStructure = `/${serviceSlug}/${suburbSlug}-${stateSlug}`;
  return urlStructure;
};

export const convertServiceSlugAndLocationToUrlStructure = (
  serviceSlug: string,
  suburb: string | undefined,
  stateName: string | undefined
): string => {
  const suburbSlug = suburb?.toLowerCase().replace(/ /g, '-');
  const stateSlug = stateName?.toLowerCase();

  const currentServiceSlug = Object.keys(SERVICE_TYPE_BY_NAME_MAPPING).includes(serviceSlug)
    ? serviceSlug
    : DEFAULT_SERVICE_SLUG;

  const urlStructure = `/${currentServiceSlug}/${suburbSlug}-${stateSlug}`;
  return urlStructure;
};

const serviceDetailsReverseMapping: { [key: string]: number } = {
  petHosting: PET_HOSTING_SERVICE_ID,
  petSitting: PET_SITTING_SERVICE_ID,
  petDayCare: PET_DAY_CARE_SERVICE_ID,
  houseVisiting: HOUSE_VISITING_SERVICE_ID,
  dogWalking: DOG_WALKING_SERVICE_ID,
  grooming: DOG_GROOMING_SERVICE_ID,
  training: DOG_TRAINING_SERVICE_ID,
};

export const getServiceTypeIdFromSearchFilters = (serviceDetails: ServiceFilterDetails): number => {
  const serviceTypeId = serviceDetailsReverseMapping[serviceDetails.type];
  return serviceTypeId || DEFAULT_SERVICE_TYPE_ID;
};
