import { useEffect } from 'react';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const AnalyticsInitiator = ({ children }: Props): ReactElement => {
  useEffect(() => {
    if (!window.analytics || !window.analytics.load) {
      return;
    }

    window.analytics.load(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || '');
  }, []);

  return <>{children}</>;
};
