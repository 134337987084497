import { yupToFormErrors } from 'formik';
import * as yup from 'yup';

import { CHECKBOXGROUP_NAME, CHECKBOX_TERMS_NAME } from '../constants';

import type { TermsConfirmationFormValues } from './TermsConfirmationForm';
import type { FormikErrors } from 'formik';

const termsFormValidationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  [CHECKBOXGROUP_NAME]: yup
    .array()
    .of(yup.string().required())
    .test('required-validation', 'Please accept terms and conditions', (value) => {
      if (!value || !Array.isArray(value)) {
        return false;
      }
      return value.includes(CHECKBOX_TERMS_NAME);
    }),
});

export const validateTermsForm = (
  values: TermsConfirmationFormValues
): Promise<FormikErrors<TermsConfirmationFormValues> | null> =>
  termsFormValidationSchema
    .validate(values, { abortEarly: false })
    .then(() => null)
    .catch((errors: unknown) => {
      if (global.document !== undefined) {
        // scroll to the error element in the next tick to give formik and react
        // some time to set error into the dom
        setTimeout(() => {
          const selector = global.document.querySelector('[data-error-message]');
          if (selector) {
            selector.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }, 0);
      }
      return yupToFormErrors(errors);
    });

export const mobileValidationSchema = yup.object({
  phone: yup
    .string()
    .required('Please enter your phone number')
    .matches(/^04\d{2}\s\d{3}\s\d{3}$/, 'Please enter a valid phone number'),
});

export const codeValidationSchema = yup.object({
  code: yup
    .string()
    .required('Please enter the 5 digit code')
    .matches(/^\d{5}$/, 'Please enter the 5 digit code'),
});
