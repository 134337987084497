import { PAGE_LAYOUT_CONFIG, MOBILE, DESKTOP } from '~/common/constants/pageLayout';

export const shouldHeaderBeDisplayed = (route: string, isMediumFromViewport: boolean): boolean => {
  const pageConfig = PAGE_LAYOUT_CONFIG[route] || {};

  if (!pageConfig.header) {
    return false;
  }

  return isMediumFromViewport
    ? pageConfig.header.includes(DESKTOP)
    : pageConfig.header.includes(MOBILE);
};

export const shouldFooterBeDisplayed = (route: string, isMediumFromViewport: boolean): boolean => {
  const pageConfig = PAGE_LAYOUT_CONFIG[route] || {};

  if (!pageConfig.footer) {
    return false;
  }

  return isMediumFromViewport
    ? pageConfig.footer.includes(DESKTOP)
    : pageConfig.footer.includes(MOBILE);
};
