import classnames from 'classnames';
import { useField } from 'formik';

import styles from './ErrorBoundary.module.css';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  fieldName: string;
};

const ErrorBoundary = ({ fieldName, children }: Props): ReactElement => {
  const [, { error }] = useField(fieldName);

  const errorMessageResolver = (): string => error || `Field "${fieldName}" is required`;

  return (
    <div className={classnames({ [styles.root]: !!error })}>
      <>
        {children}
        {!!error && (
          <div className={styles.error} data-error-message>
            {errorMessageResolver()}
          </div>
        )}
      </>
    </div>
  );
};

export { ErrorBoundary };
