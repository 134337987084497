import { Button, LayoutGridContainer, TextField } from '@madpaws/design-system';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { SIGN_IN_ROUTE } from '~/common/constants/auth';
import { trackEvent } from '~/components/analytics/analytics';
import { USER_CONTINUE_LOGIN_CLICK } from '~/components/analytics/constants';
import { AuthApi } from '~/services/auth/authApi';

import styles from './AuthDialog.module.css';
import { emailValidationSchema } from './utils';

import type { ChangeEvent, ReactElement } from 'react';

type EmailSignUpFormValues = {
  email: string;
};

type Props = {
  onLoginSuccessRedirectUrl?: string;
};

export const EmailSignUpForm = ({ onLoginSuccessRedirectUrl }: Props): ReactElement => {
  const [formError, setFormError] = useState<string | null>(null);
  const router = useRouter();
  const formInitialValues = { email: '' };

  const onSubmitForm = async (values: EmailSignUpFormValues): Promise<void> => {
    const response = await AuthApi.validateEmail(values.email);

    if (response) {
      router.push(
        `${SIGN_IN_ROUTE}?redirectUrl=${onLoginSuccessRedirectUrl ?? router.asPath}&email=${
          values.email
        }&step=${response.isTaken ? 'login' : 'sign-up'}`
      );
    }

    trackEvent(USER_CONTINUE_LOGIN_CLICK, { email: values.email, grant_type: 'email' });
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={onSubmitForm}
      validationSchema={emailValidationSchema}
    >
      {({ values, isSubmitting, isValid, setFieldValue, submitForm }): ReactElement => {
        const isSubmitButtonDisabled = isSubmitting || !isValid;

        const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
          const {
            target: { name, value },
          } = event;

          setFieldValue(name, value);
          // clear form error when user starts typing
          setFormError(null);
        };

        return (
          <div className={styles.form}>
            <LayoutGridContainer rowGap="default">
              <TextField
                isLabelVisuallyHidden
                label="Email"
                name="email"
                onChange={onTextFieldChange}
                value={values.email}
              />
            </LayoutGridContainer>
            <p className={styles.error}>{formError}</p>

            <div className={styles.buttonContainer}>
              <Button
                isDisabled={isSubmitButtonDisabled}
                isFullBleed
                label="Continue"
                onClick={submitForm}
                variant="secondary"
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
