import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import { getAuthorizationHeaderFromCookie } from '~/utils/auth';

import type { AxiosError, AxiosInstance } from 'axios';

export class RestApi {
  protected static handleError<T>(error: AxiosError): T | null {
    Sentry.captureException(error);
    return null;
  }

  private static initialize(baseUrl: string | undefined): AxiosInstance {
    return axios.create({
      baseURL: baseUrl,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
        ...getAuthorizationHeaderFromCookie(),
      },
    });
  }

  protected static snifferApi: AxiosInstance = RestApi.initialize(
    process.env.NEXT_PUBLIC_SNIFFER_API_BASE_URL
  );

  protected static woofApi: AxiosInstance = RestApi.initialize(
    process.env.NEXT_PUBLIC_API_BASE_URL
  );
}
