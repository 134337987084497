import { ApolloLink } from '@apollo/client';
import Cookies from 'js-cookie';

import { ACCESS_TOKEN_COOKIE_NAME } from '~/common/constants/auth';

export const getAccessToken = (): string | null => {
  const userTokenCookie = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  if (!userTokenCookie) {
    return null;
  }

  let tokenData: { access_token: string };
  try {
    tokenData = JSON.parse(decodeURIComponent(userTokenCookie)).tokens;
  } catch {
    return null;
  }

  return tokenData.access_token;
};

export const authLink = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken();

  if (accessToken) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }));
  }

  return forward(operation);
});
