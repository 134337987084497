import { TappableLinkContainer } from '@madpaws/design-system';
import React, { Fragment } from 'react';

import { STATIC_CONTEXTUAL_LINKS } from './constants';
import { useContextualLinks } from './context';
import { getTopPetSittingCitiesLinks } from './utils';
import { PlatformFooterNavigationLayout } from '../PlatformFooterNavigationLayout/PlatformFooterNavigationLayout';
import { PlatformFooterNavigationLayoutHeading } from '../PlatformFooterNavigationLayout/PlatformFooterNavigationLayoutHeading';

import type { ReactElement } from 'react';

const PlatformFooterContextualLinks = (): ReactElement => {
  const contextualLinks = useContextualLinks();

  const TOP_PET_SITTING_CITIES_LINKS = getTopPetSittingCitiesLinks();

  return (
    <div>
      {contextualLinks
        .concat(TOP_PET_SITTING_CITIES_LINKS)
        .concat(STATIC_CONTEXTUAL_LINKS)
        .map(({ heading, links }) => (
          <Fragment key={heading}>
            <PlatformFooterNavigationLayoutHeading>{heading}</PlatformFooterNavigationLayoutHeading>
            <PlatformFooterNavigationLayout isContextualLinks key={heading}>
              {links.map(({ label, href }) => (
                <li key={label}>
                  <TappableLinkContainer>
                    <a href={href}>{label}</a>
                  </TappableLinkContainer>
                </li>
              ))}
            </PlatformFooterNavigationLayout>
          </Fragment>
        ))}
    </div>
  );
};

export { PlatformFooterContextualLinks };
