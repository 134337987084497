import { Heading, LayoutVerticalSpacer } from '@madpaws/design-system';
import React from 'react';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const PlatformFooterNavigationLayoutHeading = ({ children }: Props): ReactElement => (
  <LayoutVerticalSpacer>
    <Heading fontFamily="heading" size="small3x">
      {children}
    </Heading>
  </LayoutVerticalSpacer>
);
