import { PET_TYPES } from '~/common/constants/app';
import { BOOKING_TYPE_RWB } from '~/components/utils/search';

import {
  OVERNIGHT_SERVICE_NAMES,
  RWB_SERVICE_NAMES,
  SITTER_HOME_BASED_SERVICE_NAMES,
  WEEK_DAYS,
} from './constants';

import type { PetTypes, SearchFilters, WeekDays } from '~/common/types/search';

export const isOvernightServiceSelected = (serviceType: string): boolean =>
  OVERNIGHT_SERVICE_NAMES.includes(serviceType);

export const isRwbServiceSelected = (serviceType: string, bookingType: string): boolean =>
  RWB_SERVICE_NAMES.includes(serviceType) && bookingType === BOOKING_TYPE_RWB;

export const isSitterHomeBasedServiceSelected = (serviceType: string): boolean =>
  SITTER_HOME_BASED_SERVICE_NAMES.includes(serviceType);

export const transformServiceOptionsToString = (
  options: {
    description?: string;
    label: string;
    value: string;
  }[],
  serviceType: string,
  bookingType: string,
  isServiceTypeDisplayValueHasDescription = true
): string => {
  const service = options.find((option) => option.value === serviceType);
  if (!service) {
    return '';
  }

  let serviceLabel = service.label;

  if (isServiceTypeDisplayValueHasDescription && service.description) {
    serviceLabel += ` - ${service.description}`;
  }

  if (RWB_SERVICE_NAMES.includes(service.value) && bookingType === BOOKING_TYPE_RWB) {
    serviceLabel += ` - Repeat Weekly`;
  }

  return serviceLabel;
};

export const transformServiceOptionsToStringWithNoDescription = (
  options: {
    description?: string;
    label: string;
    value: string;
  }[],
  serviceType: string,
  bookingType: string
): string => {
  const service = options.find((option) => option.value === serviceType);
  if (!service) {
    return '';
  }

  let serviceLabel = service.label;

  if (RWB_SERVICE_NAMES.includes(service.value) && bookingType === BOOKING_TYPE_RWB) {
    serviceLabel += ` - Repeat Weekly`;
  }

  return serviceLabel;
};

export const transformPetSelectionToString = (petTypes: PetTypes): string =>
  Object.keys(petTypes)
    .filter((pet) => Number(petTypes[pet]) > 0)
    .map((petKey) => {
      const petLabel = PET_TYPES.find(({ name }) => name === petKey);
      const label = Number(petTypes[petKey]) > 1 ? petLabel?.labelPlural : petLabel?.label;
      return `${petTypes[petKey]} ${label?.toLowerCase()}`;
    })
    .join(', ');

export const transformWeekDaysObjectToString = (weekDaysObj: WeekDays): string =>
  Object.entries(weekDaysObj)
    .reduce((selectedDays: string[], [key, value]) => {
      if (value) {
        const dayObject = WEEK_DAYS.find(({ name }) => name === key);
        if (dayObject) {
          selectedDays.push(dayObject.displayValue);
        }
      }

      return selectedDays;
    }, [])
    .join(', ');

/*
 * helper to define if dates are choosen correctly by user in search form
 */
export const checkChronology = (values: SearchFilters): boolean => {
  const {
    service: { type: serviceType },
    chronology: { endDate, startDate, scheduledDates, rwbStartDate, weekDays },
    bookingType,
  } = values;
  /*
   * if one of the overnight service is selected
   * then start and end dates should not be empty
   */
  if (
    isOvernightServiceSelected(serviceType) &&
    endDate !== undefined &&
    startDate !== undefined &&
    endDate !== '' &&
    startDate !== ''
  ) {
    return true;
  }
  /*
   * if one of the rwb service is selected and booking type is rwb
   * then rwbStartDate and any weekdays should not be empty
   */
  if (
    isRwbServiceSelected(serviceType, bookingType) &&
    rwbStartDate !== undefined &&
    rwbStartDate !== '' &&
    Object.values(weekDays || {}).some((value) => value !== false) !== false
  ) {
    return true;
  }
  /*
   * if any of above scenarios not matched
   * scheduledDates should not be empty
   */
  if (
    !isRwbServiceSelected(serviceType, bookingType) &&
    !isOvernightServiceSelected(serviceType) &&
    scheduledDates !== undefined &&
    scheduledDates.length > 0
  ) {
    return true;
  }

  return false;
};

/*
 * helper to define if at least 1 pets are choosen by user in search form
 */
export const checkPets = (values: SearchFilters): boolean => {
  const { petTypes } = values;
  // parseInt here because Formik keeps value as 0
  // when amount of pets was decreased from 1
  return Object.values(petTypes || {}).some(
    (value) => parseInt(value) !== 0 && value !== '0' && value != null && value !== ''
  );
};
