import { SITTER_PROFILE_PREFIX } from '../constants/app';
import { PROFILE_ABOUT_PAGE_URL } from '../constants/pageLayout';
import { ServicePreferencesStatus } from '../types/sitter';

const statesMappings: { [key: string]: string } = {
  'new-south-wales': 'nsw',
  queensland: 'qld',
  'south-australia': 'sa',
  tasmania: 'tas',
  victoria: 'vic',
  'western-australia': 'wa',
};

// NOTE: exported for unit testing purposes only
export const transformStringForUrl = (value: string): string =>
  value.trim().split(/\W+/g).join('-').toLowerCase();

// NOTE: exported for unit testing purposes only
export const getShortStateName = (state: string): string => {
  const stateFullNames = Object.keys(statesMappings);
  const transformedState = transformStringForUrl(state);

  if (stateFullNames.includes(transformedState)) {
    return statesMappings[transformedState];
  }

  return transformedState;
};

export const getSitterProfileUrl = (city: string, rewriteUrl: string, state: string): string => {
  const shortStateName = getShortStateName(state);
  const transformedCity = transformStringForUrl(city);

  return `/${SITTER_PROFILE_PREFIX}/${transformedCity}-${shortStateName}/${rewriteUrl}`;
};

export const transformProfileUrl = (city: string, rewriteUrl: string, state: string): string =>
  // If any of the three is empty, return the static alternative link
  city || state || rewriteUrl
    ? getSitterProfileUrl(city, rewriteUrl, state)
    : PROFILE_ABOUT_PAGE_URL;

export const transformSitterServiceStatus = (
  status: number,
  providesRwb: boolean
): ServicePreferencesStatus => {
  if (!status) {
    return ServicePreferencesStatus.INACTIVE;
  }

  if (providesRwb) {
    return ServicePreferencesStatus.REPEAT;
  }

  return ServicePreferencesStatus.ACTIVE;
};
