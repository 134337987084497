import {
  AnimatedSpinner,
  Button,
  ButtonLink,
  CheckboxGroup,
  Divider,
  Heading,
  LayoutGridContainer,
  LayoutVerticalSpacer,
  TappableLinkContainer,
  TextField,
} from '@madpaws/design-system';
import { Formik } from 'formik';
import React, { useEffect } from 'react';

import { LEGAL_LINKS } from '~/common/constants/app';
import {
  TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID,
  TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID_VALUES,
} from '~/components/SplitIO/constants';
import { useSplitIO } from '~/components/SplitIO/useSplitIO';
import { getSegmentAnonymousId, trackEvent } from '~/components/analytics/analytics';
import { VIEWED_CONFIRM_NAME_AND_ACCEPT_TERMS_EVENT_NAME } from '~/components/analytics/constants';

import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary';
import styles from './GoogleSignUpDialog.module.css';
import { validateTermsForm } from './utils';
import {
  CHECKBOXGROUP_NAME,
  CHECKBOX_OPTIONS,
  CHECKBOX_SPECIAL_OFFERS_NAME,
  CHECKBOX_TERMS_NAME,
} from '../constants';

import type { GoogleSignUpProps } from '../GoogleOneTapLoginProvider';
import type { CheckboxGroupOnChange } from '@madpaws/design-system';
import type { FormikHelpers } from 'formik';
import type { ReactElement, ChangeEvent } from 'react';
import type { LoginResponse } from '~/api/graphql/googleSignIn/typeDefs';

type Props = {
  data: LoginResponse | null;
  formError: string | null;
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: (data: GoogleSignUpProps) => void;
};

export type TermsConfirmationFormValues = {
  checkboxOptions: string[];
  firstName: string;
  lastName: string;
};

export const TermsConfirmationForm = ({
  data,
  formError,
  isSubmitting: isWaitingForAPIResponse,
  onCancel,
  onSubmit,
}: Props): ReactElement => {
  const { treatments, isSplitIoLoading } = useSplitIO();

  const formInitialValues = {
    firstName: data?.first_name ?? '',
    lastName: data?.last_name ?? '',
    checkboxOptions: [CHECKBOX_SPECIAL_OFFERS_NAME],
  };

  useEffect(() => {
    trackEvent(VIEWED_CONFIRM_NAME_AND_ACCEPT_TERMS_EVENT_NAME, {
      anonymous_id: getSegmentAnonymousId(),
      email: data?.email,
      type: 'oneTap',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = async (
    values: TermsConfirmationFormValues,
    { setErrors }: FormikHelpers<TermsConfirmationFormValues>
  ): Promise<void> => {
    const errors = await validateTermsForm(values);

    if (errors) {
      setErrors(errors);
      return;
    }

    const { firstName, lastName, checkboxOptions } = values;
    const signUpData = {
      firstName,
      lastName,
      googleToken: data?.id_token ?? '',
      receiveSpecialOffers: checkboxOptions.includes(CHECKBOX_SPECIAL_OFFERS_NAME),
      newUserAcceptedTerms: checkboxOptions.includes(CHECKBOX_TERMS_NAME),
    };

    onSubmit(signUpData);
  };

  const renderLegalLinks = (link: typeof LEGAL_LINKS[0], index: number): ReactElement => (
    <TappableLinkContainer key={index}>
      <a href={link.href} rel="noopener noreferrer" target="_blank">
        {link.label} {index === 0 && ','}
      </a>
    </TappableLinkContainer>
  );

  return (
    <Formik initialValues={formInitialValues} onSubmit={onSubmitForm} validate={validateTermsForm}>
      {({ isValid, isSubmitting, values, setFieldValue, submitForm }): ReactElement => {
        const { firstName, lastName, checkboxOptions } = values;
        const isTwoFactorAuthEnabled = !isSplitIoLoading
          ? treatments[TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID] ===
            TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID_VALUES.ON
          : false;
        const isSubmitButtonDisabled = isWaitingForAPIResponse || isSubmitting || !isValid;

        const onCheckboxChange = ({ target }: CheckboxGroupOnChange): void => {
          setFieldValue(CHECKBOXGROUP_NAME, target.value.value);
        };

        const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
          const {
            target: { name, value },
          } = event;

          setFieldValue(name, value);
        };

        return (
          <>
            <LayoutGridContainer rowGap="default">
              {!isTwoFactorAuthEnabled ? (
                <>
                  <Heading size="small2x">Almost there</Heading>
                  <p>Confirm your name and accept terms to complete sign up.</p>
                </>
              ) : (
                <></>
              )}
              <ErrorBoundary fieldName="firstName">
                <TextField
                  label="First name"
                  name="firstName"
                  onChange={onTextFieldChange}
                  value={firstName}
                />
              </ErrorBoundary>
              <ErrorBoundary fieldName="lastName">
                <TextField
                  label="Last name"
                  name="lastName"
                  onChange={onTextFieldChange}
                  value={lastName}
                />
              </ErrorBoundary>
              <ErrorBoundary fieldName={CHECKBOXGROUP_NAME}>
                <CheckboxGroup
                  legend=""
                  name={CHECKBOXGROUP_NAME}
                  onChange={onCheckboxChange}
                  options={CHECKBOX_OPTIONS}
                  value={{ value: checkboxOptions }}
                />
              </ErrorBoundary>
            </LayoutGridContainer>
            <div className={styles.privacyLinks}>{LEGAL_LINKS.map(renderLegalLinks)}</div>
            <p className={styles.error}>{formError}</p>
            <LayoutVerticalSpacer>
              <Divider />
            </LayoutVerticalSpacer>
            <div className={styles.buttonContainer}>
              <ButtonLink onClick={onCancel}>Cancel</ButtonLink>
              <Button
                iconLeading={isWaitingForAPIResponse ? <AnimatedSpinner /> : undefined}
                isDisabled={isSubmitButtonDisabled}
                label={isTwoFactorAuthEnabled ? 'Continue' : 'Sign up'}
                onClick={submitForm}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};
