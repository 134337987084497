import { HELP_CENTRE_LINK, PET_CHEMIST_BASE_LINK } from '~/common/constants/externalLinks';

import type { Link } from './types';

type Links = Link[];

export const ABOUT_LINKS: Links = [
  {
    label: 'About Us',
    href: 'https://www.madpaws.com.au/about/',
  },
  {
    label: 'Mad Paws Blog',
    href: 'https://www.madpaws.com.au/blog/',
  },
  {
    label: 'Press',
    href: 'https://www.madpaws.com.au/about/in-the-press/',
  },
  {
    label: 'Jobs',
    href: 'https://www.madpaws.com.au/about/jobs/',
  },
  {
    label: 'Reviews',
    href: 'https://www.madpaws.com.au/about/mad-paws-reviews/',
  },
  {
    label: 'Investor Centre',
    href: 'https://www.madpaws.com.au/investor-centre/',
  },
  {
    label: 'Sustainability',
    href: 'https://sustainability.madpaws.com.au/',
  },
];

export const SUPPORT_LINKS: Links = [
  {
    label: 'Help Centre',
    href: HELP_CENTRE_LINK,
  },
  {
    label: 'Accident Cover',
    href: 'https://madpaws.brainfi.sh/articles/mad-paws-accident-cover-what-is-included-UZbCSbI7xa',
  },
];

export const OUR_BRANDS_LINKS: Links = [
  {
    label: 'Pet Chemist',
    href: `${PET_CHEMIST_BASE_LINK}/?utm_source=madpaws&utm_medium=futures&utm_campaign=footer`,
    isExternal: true,
  },
  {
    label: 'Waggly',
    href:
      'https://www.wagglyclub.com.au/?utm_source=madpaws&utm_medium=futures&utm_campaign=footer',
    isExternal: true,
  },
  {
    label: 'Mad Paws Pet Insurance',
    href:
      'https://www.madpawspetinsurance.com.au/?utm_source=madpaws&utm_medium=futures&utm_campaign=footer',
    isExternal: true,
  },
];

export const OUR_PARTNERS_LINKS: Links = [
  {
    label: 'Qantas',
    href: 'https://www.madpaws.com.au/pet-sitters/qantas/',
    image: 'Qantas_logo.svg',
    height: 20,
    width: 101,
    isExternal: true,
  },
  {
    label: 'Sharing Hub',
    href: 'https://www.thesharinghub.com.au/',
    image: 'TheSharingHub_logo.svg',
    height: 40,
    width: 76,
    isExternal: true,
  },
];

export const OUR_APP_LINKS: Links = [
  {
    label: 'App Store',
    href: 'https://apps.apple.com/au/app/mad-paws-find-pet-sitters/id983899686?ls=1',
    image: 'App_Store_badge.svg',
    height: 40,
    width: 120,
    isExternal: true,
  },
  {
    label: 'Google Play Store',
    href: 'https://play.google.com/store/apps/details?id=au.com.madpaws',
    image: 'Google_Play_badge.svg',
    height: 40,
    width: 120,
    isExternal: true,
  },
];
