import { PlatformFooterMainNavigationSection } from './PlatformFooterMainNavigationSection/PlatformFooterMainNavigationSection';
import {
  ABOUT_LINKS,
  SUPPORT_LINKS,
  OUR_BRANDS_LINKS,
  OUR_PARTNERS_LINKS,
  OUR_APP_LINKS,
} from './constants';
import { PlatformFooterNavigationLayout } from '../PlatformFooterNavigationLayout/PlatformFooterNavigationLayout';

import type { ReactElement } from 'react';

export const PlatformFooterMainNavigation = (): ReactElement => (
  <PlatformFooterNavigationLayout>
    <PlatformFooterMainNavigationSection heading="About" links={ABOUT_LINKS} />
    <PlatformFooterMainNavigationSection heading="Support" links={SUPPORT_LINKS} />
    <PlatformFooterMainNavigationSection heading="Our Brands" links={OUR_BRANDS_LINKS} />
    <PlatformFooterMainNavigationSection heading="Our Partners" links={OUR_PARTNERS_LINKS} />
    <PlatformFooterMainNavigationSection heading="Our App" links={OUR_APP_LINKS} />
  </PlatformFooterNavigationLayout>
);
