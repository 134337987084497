import * as yup from 'yup';

import {
  isOvernightServiceSelected,
  isRwbServiceSelected,
} from '~/components/SearchFiltersForm/utils';

export const locationValidationObject = yup.object({
  address: yup.object({
    lat: yup.number().required(),
    lng: yup.number().required(),
    postcode: yup.string().required(),
    stateName: yup.string().required(),
    suburb: yup.string().required(),
  }),
});

export const serviceValidationObject = yup.object({
  type: yup
    .string()
    .test('length', 'Please choose pet service type', (service) => {
      if (!service) {
        return false;
      }

      return service.toString().length > 0;
    })
    .required(),
});

export const petTypesValidationObject = yup
  .object({
    puppy: yup.string().nullable(),
    smallDog: yup.string().nullable(),
    mediumDog: yup.string().nullable(),
    largeDog: yup.string().nullable(),
    giantDog: yup.string().nullable(),
    cat: yup.string().nullable(),
    creature: yup.string().nullable(),
    bird: yup.string().nullable(),
  })
  .test('required-validation', 'Pets required', (petTypes) =>
    Object.values(petTypes || {}).some((value) => value !== '0' && value != null && value !== '')
  );

export const chronologyValidationObject = yup
  .object({
    endDate: yup.string().nullable(),
    rwbStartDate: yup.string().nullable(),
    scheduledDates: yup.array().of(yup.string().required()),
    startDate: yup.string().nullable(),
    weekDays: yup.object({
      friday: yup.boolean().required(),
      monday: yup.boolean().required(),
      saturday: yup.boolean().required(),
      sunday: yup.boolean().required(),
      thursday: yup.boolean().required(),
      tuesday: yup.boolean().required(),
      wednesday: yup.boolean().required(),
    }),
  })
  .test('required-validation', 'Dates required', function (chronology) {
    const { endDate, startDate, scheduledDates, rwbStartDate, weekDays } = chronology;

    // reading service and bookingType from yup.ref to calculate validation
    const serviceRef: { type: string } = this.resolve(yup.ref('service'));
    const bookingTypeRef: string = this.resolve(yup.ref('bookingType'));

    // following conditions are taken from DateSelector.tsx

    /*
     * if one of the overnight service is selected
     * then start and end dates should not be empty
     */
    if (
      isOvernightServiceSelected(serviceRef.type) &&
      endDate !== undefined &&
      startDate !== undefined &&
      endDate !== '' &&
      startDate !== ''
    ) {
      return true;
    }

    /*
     * if one of the rwb service is selected and booking type is rwb
     * then rwbStartDate and any weekdays should not be empty
     */
    if (
      isRwbServiceSelected(serviceRef.type, bookingTypeRef) &&
      rwbStartDate !== undefined &&
      rwbStartDate !== '' &&
      Object.values(weekDays || {}).some((value) => value !== false) !== false
    ) {
      return true;
    }

    /*
     * if any of above scenarios not matched
     * scheduledDates should not be empty
     */
    if (
      !isRwbServiceSelected(serviceRef.type, bookingTypeRef) &&
      !isOvernightServiceSelected(serviceRef.type) &&
      scheduledDates !== undefined &&
      scheduledDates.length > 0
    ) {
      return true;
    }

    return false;
  });

export const PHONE_INPUT_MASK = 'xxxx xxx xxx';
