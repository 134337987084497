import { gql } from '@apollo/client';

export const GET_FEATURE_FLAGS = gql`
  query featureFlags {
    featureFlags {
      name
      value
    }
  }
`;
