export const ACCESS_TOKEN_COOKIE_NAME = 'USER_DATA';
export const ACCESS_TOKEN_TYPE = 'Bearer';
export const MD_AUTH_LOCAL_STORAGE_KEY = 'md_auth';
export const QANTAS_USER_COOKIE_NAME = 'qantasuser';
export const QANTAS_TOKEN_COOKIE_NAME = 'qtoken';
export const QANTAS_USER_COOKIE_VALUE = '1';
export const QANTAS_UTM_SOURCE_VALUE = 'qantas';
export const QANTAS_COOKIE_LIFE_TIME_IN_MONTHS = 1;

export const FROM_IN_APP_BROWSER = 'InAppBrowser';
export const SIGN_IN_ROUTE = '/sign-in';
export const SIGN_UP_ROUTE = '/sign-up';
