import { useMutation } from '@apollo/client';
import {
  AnimatedSpinner,
  Button,
  ButtonLink,
  Divider,
  Heading,
  IconChevronLeft,
  LayoutGridContainer,
  LayoutVerticalSpacer,
  TextField,
} from '@madpaws/design-system';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { PHONE_INPUT_MASK } from '~/common/utils/validation';
import { getSegmentAnonymousId, trackEvent } from '~/components/analytics/analytics';
import {
  CLICKED_VERIFY_MOBILE_EVENT_NAME,
  MOBILE_VERIFIED_EVENT_NAME,
  VIEWED_MOBILE_VERIFICATION_EVENT_NAME,
  VIEWED_TWO_FACTOR_CODE_EVENT_NAME,
} from '~/components/analytics/constants';

import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary';
import styles from './GoogleSignUpDialog.module.css';
import { codeValidationSchema, mobileValidationSchema } from './utils';
import { SEND_MOBILE_VERIFICATION, VERIFY_2FA_CODE } from '../queries';

import type { ReactElement, ChangeEvent } from 'react';
import type { LoginResponse } from '~/api/graphql/googleSignIn/typeDefs';

type MobileVerificationFormValues = {
  code: string;
  phone: string;
};

enum MobileVerificationFormStep {
  MobileVerification = 'mobile-verification',
  CodeVerification = 'code-verification',
}

type Props = {
  data: LoginResponse | null;
  isNewUser: boolean;
  onSuccessLogin: () => void;
};

export const MobileVerificationForm = ({
  data,
  isNewUser,
  onSuccessLogin,
}: Props): ReactElement => {
  const formInitialValues = { phone: '', code: '' };
  const [formError, setFormError] = useState<string | null>(null);
  const [isWaitingForAPIResponse, setIsWaitingForAPIResponse] = useState<boolean>(false);
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [subStep, setSubStep] = useState<MobileVerificationFormStep>(
    MobileVerificationFormStep.MobileVerification
  );
  const trackEventPayload = {
    anonymous_id: getSegmentAnonymousId(),
    email: data?.email,
    type: 'oneTap',
  };

  useEffect(() => {
    trackEvent(VIEWED_MOBILE_VERIFICATION_EVENT_NAME, trackEventPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sendMobileVerification] = useMutation<{ response: string | null }>(
    SEND_MOBILE_VERIFICATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setIsWaitingForAPIResponse(false);
        setIsResendingCode(false);
        setSubStep(MobileVerificationFormStep.CodeVerification);
        trackEvent(VIEWED_TWO_FACTOR_CODE_EVENT_NAME, { ...trackEventPayload, phone: phoneNumber });
      },
      onError: (error) => {
        setFormError(error.message);
        setIsWaitingForAPIResponse(false);
        setIsResendingCode(false);
      },
    }
  );

  const [verify2FACode] = useMutation<{ response: string | null }>(VERIFY_2FA_CODE, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setIsWaitingForAPIResponse(false);
      trackEvent(MOBILE_VERIFIED_EVENT_NAME, { ...trackEventPayload, phone: phoneNumber });
      onSuccessLogin();
    },
    onError: (error) => {
      setFormError(error.message);
      setIsWaitingForAPIResponse(false);
    },
  });

  const requestMobileVerificationCode = (values: MobileVerificationFormValues): void => {
    setPhoneNumber(values.phone);
    const number = values.phone.replace(/\s/g, '').replace(/^0/, '+61');
    sendMobileVerification({ variables: { sendMobileVerificationArg: { number } } });
  };

  const onSubmitForm = async (values: MobileVerificationFormValues): Promise<void> => {
    setFormError(null);
    setIsWaitingForAPIResponse(true);
    // when it's mobile verification step
    if (subStep === MobileVerificationFormStep.MobileVerification) {
      requestMobileVerificationCode(values);
      return;
    }

    // when it's code verification step
    trackEvent(CLICKED_VERIFY_MOBILE_EVENT_NAME, {
      ...trackEventPayload,
      phone: phoneNumber,
    });
    verify2FACode({
      variables: { verify2FaCodeArg: { verificationCode: values.code, isNewUser } },
    });
  };

  const isMobileVerificationFormVisible = subStep === MobileVerificationFormStep.MobileVerification;

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={onSubmitForm}
      validationSchema={
        isMobileVerificationFormVisible ? mobileValidationSchema : codeValidationSchema
      }
    >
      {({ values, isSubmitting, isValid, setFieldValue, submitForm }): ReactElement => {
        const isSubmitButtonDisabled = isWaitingForAPIResponse || isSubmitting || !isValid;

        const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
          const {
            target: { name, value },
          } = event;

          setFieldValue(name, value);
          // clear form error when user starts typing
          setFormError(null);
        };

        const onResendClicked = (): void => {
          setIsResendingCode(true);
          requestMobileVerificationCode(values);
        };

        const onBackClicked = (): void => {
          setFormError(null);
          setSubStep(MobileVerificationFormStep.MobileVerification);
        };

        const maskedPhoneNumber = `XXXX XXX${values.phone.slice(-5)}`;

        return (
          <>
            {isMobileVerificationFormVisible ? (
              <>
                <LayoutGridContainer rowGap="default">
                  <Heading size="small2x">Verify phone number</Heading>
                  <p>Your phone number helps keep your account secure.</p>
                  <LayoutVerticalSpacer>
                    <ErrorBoundary fieldName="phone">
                      <TextField
                        label="Phone number"
                        mask={PHONE_INPUT_MASK}
                        name="phone"
                        onChange={onTextFieldChange}
                        type="tel"
                        value={values.phone}
                      />
                    </ErrorBoundary>
                  </LayoutVerticalSpacer>
                </LayoutGridContainer>
                <LayoutVerticalSpacer>
                  <p className={styles.error}>{formError}</p>
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer>
                  <Divider />
                </LayoutVerticalSpacer>
                <div className={styles.buttonContainer}>
                  <Button
                    iconLeading={isWaitingForAPIResponse ? <AnimatedSpinner /> : undefined}
                    isDisabled={isSubmitButtonDisabled}
                    isFullBleed
                    label="Continue"
                    onClick={submitForm}
                  />
                </div>
              </>
            ) : (
              <>
                <LayoutGridContainer rowGap="default">
                  <Heading size="small2x">Enter 5 digit code</Heading>
                  <p>Sent to {maskedPhoneNumber}</p>
                  <ErrorBoundary fieldName="code">
                    <TextField
                      label="Code"
                      name="code"
                      onChange={onTextFieldChange}
                      value={values.code}
                    />
                  </ErrorBoundary>
                </LayoutGridContainer>
                <p className={styles.error}>{formError}</p>
                <div className={styles.resend}>
                  Didn&apos;t receive a text?&nbsp;
                  <ButtonLink onClick={onResendClicked}>Send again</ButtonLink>
                  {isResendingCode ? <AnimatedSpinner /> : undefined}
                </div>
                <LayoutVerticalSpacer>
                  <Divider />
                </LayoutVerticalSpacer>
                <div className={styles.buttonContainer}>
                  <Button
                    iconLeading={<IconChevronLeft />}
                    label="Back"
                    onClick={onBackClicked}
                    variant="transparent"
                  />
                  <Button
                    iconLeading={isWaitingForAPIResponse ? <AnimatedSpinner /> : undefined}
                    isDisabled={isSubmitButtonDisabled}
                    label="Verify"
                    onClick={submitForm}
                  />
                </div>
              </>
            )}
          </>
        );
      }}
    </Formik>
  );
};
