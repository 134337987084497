import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import { trackPage } from '~/components/analytics/analytics';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const AnalyticsPageTracker = ({ children }: Props): ReactElement => {
  const isMounted = useRef<boolean>(false);
  const lastVisitedPathname = useRef<string>('');

  const router = useRouter();

  const handleRouteChangeComplete = useCallback((url: string): void => {
    const [pathname] = url.split('?');
    if (pathname === lastVisitedPathname.current) {
      return;
    }

    lastVisitedPathname.current = pathname;
    trackPage();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [handleRouteChangeComplete, router.events]);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    isMounted.current = true;

    lastVisitedPathname.current = window.location.pathname;
    trackPage();
  }, []);

  return <>{children}</>;
};

export { AnalyticsPageTracker };
