import { gql } from '@apollo/client';

export const SIGN_IN_WITH_GOOGLE = gql`
  mutation SignInWithGoogle($googleLoginArgs: GoogleLoginArgs!) {
    signInWithGoogle(googleLoginArgs: $googleLoginArgs) {
      access_token
      email
      expires_in
      first_name
      id_token
      isUserPhoneNumberVerified
      last_name
      refresh_token
      token_type
      userNeedsToAcceptTerms
    }
  }
`;

export const SEND_MOBILE_VERIFICATION = gql`
  mutation SendMobileVerification($sendMobileVerificationArg: SendMobileVerificationArg!) {
    sendMobileVerification(sendMobileVerificationArg: $sendMobileVerificationArg)
  }
`;

export const VERIFY_2FA_CODE = gql`
  mutation Verify2FACode($verify2FaCodeArg: Verify2FACodeArg!) {
    verify2FACode(verify2FACodeArg: $verify2FaCodeArg)
  }
`;
