import { Context as ResponsiveContext } from 'react-responsive';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  inferredScreenWidth: number;
};

export const ResponsiveContextProvider = ({
  children,
  inferredScreenWidth,
}: Props): ReactElement => {
  if (typeof window !== 'undefined') {
    return <>{children}</>;
  }

  return (
    <ResponsiveContext.Provider value={{ width: inferredScreenWidth }}>
      {children}
    </ResponsiveContext.Provider>
  );
};
