import classnames from 'classnames';
import React from 'react';

import styles from './PlatformFooterNavigationLayout.module.css';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isContextualLinks?: boolean;
};

export const PlatformFooterNavigationLayout = ({
  children,
  isContextualLinks = false,
}: Props): ReactElement => {
  const Element = isContextualLinks ? 'ul' : 'div';

  return (
    <Element className={classnames(styles.root, { [styles.isContextualLinks]: isContextualLinks })}>
      {children}
    </Element>
  );
};
